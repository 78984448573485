<template>
	<div class="search_banner">
		<div id="datepicker"></div>
		<div class="row" style="margin-top: 25px;">
			<div class="two_third">
				<div class="search_panel">
					<span class="date">
						<label>{{ getTranslation('FROM') }}</label>
						<input type="text" id="check_in" />
					</span>
					<span class="date">
						<label>{{ getTranslation('TO') }}</label>
						<input type="text" id="check_out" />
					</span>
				</div>
			</div>
			<div class="one_third">
				<div class="nights">
					<div class="nights_inner">
						<span id="nights">{{ nights }}</span>
						{{ nights_str }}
					</div>
				</div>
			</div>
		</div>
		<div class="search_panel" style="margin-top: 25px;" v-if="room_types.length > 0">
			<label>{{ getTranslation('INTERESTEDIN') }}</label>
			<select id="room_type" name="room_type" v-model="room_type">
				<option value="0">{{ getTranslation('ALL') }}</option>
				<option v-for="room_type in room_types" v-bind:key="room_type.ID" v-bind:value="room_type.ID">{{ room_type.NAME_IT }}</option>
			</select>
		</div>
		<div class="search_panel" style="margin-top: 25px;">
			<div class="search_panel_room" v-for="(room, index) in rooms" v-bind:key="index">
				<span class="search_panel_room_name">{{ getTranslation('ROOM') }} {{ index + 1 }}</span>
				<span class="search_panel_room_remove" v-if="index > 0" @click="removeRoom(index)"><i class="fas fa-times"></i></span>
				<span class="guests">
					<div class="number">
						<div class="minus noselect" :class="{disabled: room.adults <= 0 || room.adults <= min_adult}" @click="decreaseAdults(index)">-</div>
						<span id="adult">{{ room.adults }}</span>
						<div class="plus noselect" :class="{disabled: room.adults >= max_adult}" @click="increaseAdults(index)">+</div>
					</div>
					<label>{{ getTranslation('ADULT') }}</label>
				</span>
				<span class="guests" v-if="ages.child1_age != null">
					<div class="number">
						<div class="minus noselect" :class="{disabled: room.child1 <= 0}" @click="decreaseChild1(index)">-</div>
						<span id="child1">{{ room.child1 }}</span>
						<div class="plus noselect" :class="{disabled: room.child1 >= max_child1}" @click="increaseChild1(index)">+</div>
					</div>
					<label>{{ getTranslation('CHILD') }} ({{ ages.child1_age }})</label>
				</span>
				<span class="guests" v-if="ages.child2_age != null">
					<div class="number">
						<div class="minus noselect" :class="{disabled: room.child2 <= 0}" @click="decreaseChild2(index)">-</div>
						<span id="child2">{{ room.child2 }}</span>
						<div class="plus noselect" :class="{disabled: room.child2 >= max_child2}" @click="increaseChild2(index)">+</div>
					</div>
					<label>{{ getTranslation('CHILD') }} ({{ ages.child2_age }})</label>
				</span>
				<span class="guests" v-if="ages.infant_age != null">
					<div class="number">
						<div class="minus noselect" :class="{disabled: room.infants <= 0}" @click="decreaseInfants(index)">-</div>
						<span id="infant">{{ room.infants }}</span>
						<div class="plus noselect" :class="{disabled: room.infants >= max_infant}" @click="increaseInfants(index)">+</div>
					</div>
					<label>{{ getTranslation('INFANT') }} ({{ ages.infant_age }})</label>
				</span>
			</div>
			<div class="search_panel_add_room" @click="addRoom()" v-if="rooms.length < max_rooms">
				<div class="search_panel_add_room_plus">+</div>
				<span>{{ getTranslation('ADD_ROOM') }}</span>
			</div>
		</div>
		<div class="search_panel" style="margin-top: 25px;">
			<div class="search_button" @click="searchResults">{{ getTranslation('CHECKAVAILABILITY') }}</div>
		</div>
	</div>
</template>

<script>
import Litepicker from 'litepicker';

export default {
	name: 'SidebarSearchBanner',
	data: function () {
		return {
			picker: '',
			picker_ci: '',
			picker_co: '',
			nights: 1,
			test: ''
		}
	},
	computed: {
		ages: function() {
			return this.$store.state.ages;
		},
		room_types: function() {
			return this.$store.state.room_types;
		},
		check_in: function() {
			return this.$store.state.check_in;
		},
		check_out: function() {
			return this.$store.state.check_out;
		},
		rooms: function () {
			return this.$store.state.rooms;
		},
		max_rooms: function() {
			return this.$store.state.company_info.max_rooms;
		},
		min_adult: function() {
			return this.$store.state.company_info.min_adult;
		},
		max_adult: function() {
			return this.$store.state.company_info.max_adult;
		},
		max_child1: function() {
			return this.$store.state.company_info.max_child1;
		},
		max_child2: function() {
			return this.$store.state.company_info.max_child2;
		},
		max_infant: function() {
			return this.$store.state.company_info.max_infant;
		},
		room_type: {
			get() {
                return this.$store.state.room_type;
            },
            set(room_type) {
                this.$store.commit('setRoomType', room_type);
            }
		},
		nights_str: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		},
		calendar_lang: function() {
			return this.$store.getters.get_date_lang_code;	
		}
	},
	watch: {
		calendar_lang: function(lang) {
			this.picker.setOptions({
				lang: lang
			});

			this.picker_ci.setOptions({
				lang: lang
			});

			this.picker_co.setOptions({
				lang: lang
			});
		},
		check_in: function(check_in) {
			let check_in_date = new Date(check_in.replace(/-/g, '/'));
			let check_out_date = new Date(this.$store.state.check_out.replace(/-/g, '/'));
			let check_out_edited = false;
			if(check_out_date.getTime() <= check_in_date.getTime()) {
				check_out_date = new Date(check_in_date.getTime() + (60*60*24*1000));
				check_out_edited = true;
			}

			this.picker.setDateRange(check_in_date, check_out_date);
			this.picker.gotoDate(check_out_date);

			this.picker_ci.setDate(check_in_date);

			this.nights = this.$calculateNights(check_in_date, check_out_date);

			if(check_out_edited) {
				this.$store.commit('setCheckOut', this.$formatDate(check_out_date));
			}
		},
		check_out: function(check_out) {
			let check_in_date = new Date(this.check_in.replace(/-/g, '/'));
			let check_out_date = new Date(check_out.replace(/-/g, '/'));
			let check_in_edited = false;
			if(check_out_date.getTime() <= check_in_date.getTime()) {
				check_in_date = new Date(check_out_date.getTime() - (60*60*24*1000));
				check_in_edited = true;
			}

			this.picker.setDateRange(check_in_date, check_out_date);
			this.picker.gotoDate(check_out_date);

			this.picker_co.setDate(check_out_date);

			this.nights = this.$calculateNights(check_in_date, check_out_date);

			if(check_in_edited) {
				this.$store.commit('setCheckIn', this.$formatDate(check_in_date));
			}
		}
	},
	mounted: function () {
		var self = this;
		var today = new Date();
		today.setDate(today.getDate() - 1);
		var tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);
		this.picker = new Litepicker({
			element: document.getElementById('datepicker'),
			lang: this.calendar_lang,
			hotelMode: true,
			tooltipNumber: (totalDays) => {
				return totalDays - 1;
			},
			tooltipText: {
				one: 'notte',
				other: 'notti'
			},
			inlineMode: true,
			singleMode: false,
			autoRefresh: true,
			startDate: this.check_in,
			endDate: this.check_out,
			minDate: today,
			setup: (picker) => {
				picker.on('selected', (check_in, check_out) => {
					self.$store.commit('setCheckIn', self.$formatDate(check_in.dateInstance));
					self.$store.commit('setCheckOut', self.$formatDate(check_out.dateInstance));
				});
			}
		});

		this.picker_ci = new Litepicker({
			element: document.getElementById('check_in'),
			lang: this.calendar_lang,
			format: 'DD MMMM YYYY',
			startDate: this.check_in,
			minDate: today,
			setup: (picker) => {
				picker.on('selected', (check_in) => {
					self.$store.commit('setCheckIn', self.$formatDate(check_in.dateInstance));
				});
			}
		});

		this.picker_co = new Litepicker({
			element: document.getElementById('check_out'),
			lang: this.calendar_lang,
			format: 'DD MMMM YYYY',
			startDate: this.check_out,
			minDate: tomorrow,
			setup: (picker) => {
				picker.on('selected', (check_out) => {
					self.$store.commit('setCheckOut', self.$formatDate(check_out.dateInstance));
				});
			}
		});
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		increaseAdults: function(index) {
			let adults = this.rooms[index].adults + 1;
			this.$store.commit('setAdults', {index, adults});
		},
		decreaseAdults: function(index) {
			if(this.rooms[index].adults > 0) {
				let adults = this.rooms[index].adults - 1;
				this.$store.commit('setAdults', {index, adults});
			}			
		},
		increaseChild1: function(index) {
			let child1 = this.rooms[index].child1 + 1;
			this.$store.commit('setChild1', {index, child1});
		},
		decreaseChild1: function(index) {
			if(this.rooms[index].child1 > 0) {
				let child1 = this.rooms[index].child1 - 1;
				this.$store.commit('setChild1', {index, child1});
			}
		},
		increaseChild2: function(index) {
			let child2 = this.rooms[index].child2 + 1;
			this.$store.commit('setChild2', {index, child2});
		},
		decreaseChild2: function(index) {
			if(this.rooms[index].child2 > 0) {
				let child2 = this.rooms[index].child2 - 1;
				this.$store.commit('setChild2', {index, child2});
			}
		},
		increaseInfants: function(index) {
			let infants = this.rooms[index].infants + 1;
			this.$store.commit('setInfants', {index, infants});
		},
		decreaseInfants: function(index) {
			if(this.rooms[index].infants > 0) {
				let infants = this.rooms[index].infants - 1;
				this.$store.commit('setInfants', {index, infants});
			}
		},
		addRoom: function() {
			this.$store.commit('addRoom');
		},
		removeRoom: function(index) {
			this.$store.commit('removeRoom', index);
		},
		searchResults: function() {
			this.$store.dispatch('getSearchResults');
			this.$store.commit('setShowSidebar', false);
		}
	}
}
</script>