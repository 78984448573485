<template>
	<div class="search_changed">
		<h4>{{ getTranslation('SEARCH_CHANGED') }}</h4>
		<div class="search_changed_button_container">
			<div class="search_changed_button" @click="searchResults">{{ getTranslation('CHECKAVAILABILITY') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchChanged',
	computed: {
		booking_type: function() {
			return this.$store.state.booking_type;
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		searchResults: function() {
			if(this.booking_type == 1) {
				this.$store.dispatch('getSearchResults');
				this.$store.commit('setShowSidebar', false);
			} else if(this.booking_type == 2) {
				this.$store.dispatch('getPackagesSearchResults');
				this.$store.commit('setShowSidebar', false);
			}
		}
	}
}
</script>