<template>
	<div id="search-results-top">
		<div class="search_summary_row" v-if="check_in != ''">
			<div>
				<div>
					{{ getTranslation('RESULTSFOR') }}: &nbsp; 
					<span v-if="adults > 0"><i class="fas fa-male" v-for="index in adults" v-bind:key="index"></i> <span>{{ adults }} {{ adults_text }}</span>&nbsp;</span>
					<span v-if="child1 > 0"><i class="fas fa-child" v-for="index in child1" v-bind:key="index"></i> <span>{{ child1 }} {{ child1_text }} <span>({{ ages.child1_age }})</span></span>&nbsp;</span>
					<span v-if="child2 > 0"><i class="fas fa-child" v-for="index in child2" v-bind:key="index"></i> <span>{{ child2 }} {{ child2_text }} <span>({{ ages.child2_age }})</span></span>&nbsp;</span>
					<span v-if="infants > 0"><i class="fas fa-baby" v-for="index in infants" v-bind:key="index"></i> <span>{{ infants }} {{ infants_text }} <span>({{ ages.infant_age }})</span></span>&nbsp;</span>
				</div>
				<div>
					{{ getTranslation('INPERIOD') }}: &nbsp; 
					<span>
						<i class="far fa-calendar-alt"></i>
						{{ check_in_formatted }}
						-
						<i class="far fa-calendar-alt"></i>
						{{ check_out_formatted }} &nbsp;
					</span>
					<span class="semibold">({{nights}} {{ nights_text }})</span>
				</div>
			</div>
			<div>
				<span>{{ getTranslation('ROOM') }} <b>{{ current_room }}</b></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchResultsTop',
	computed: {
		ages: function() {
			return this.$store.state.ages;
		},
		current_room: function() {
			return this.$store.state.current_room + 1;
		},
		adults: function() {
			return this.$store.state.last_search.adults;
		},
		adults_text: function() {
			if(this.adults == 1) {
				return this.getTranslation('ADULT_SINGLE');
			} else {
				return this.getTranslation('ADULT');
			}
		},
		child1: function() {
			return this.$store.state.last_search.child1;
		},
		child1_text: function() {
			if(this.child1 == 1) {
				return this.getTranslation('CHILD_SINGLE');
			} else {
				return this.getTranslation('CHILD');
			}
		},
		child2: function() {
			return this.$store.state.last_search.child2;
		},
		child2_text: function() {
			if(this.child2 == 1) {
				return this.getTranslation('CHILD_SINGLE');
			} else {
				return this.getTranslation('CHILD');
			}
		},
		infants: function() {
			return this.$store.state.last_search.infants;
		},
		infants_text: function() {
			if(this.infants == 1) {
				return this.getTranslation('INFANT_SINGLE');
			} else {
				return this.getTranslation('INFANT');
			}
		},
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		check_in_formatted: function() {
			return this.$formatDateIt(this.check_in.replace(/-/g, '/'));
		},
		check_out: function() {
			return this.$store.state.last_search.check_out;
		},
		check_out_formatted: function() {
			return this.$formatDateIt(this.check_out.replace(/-/g, '/'));
		},
		nights: function() {
			return this.$calculateNights(new Date(this.$store.state.last_search.check_in), new Date(this.$store.state.last_search.check_out));
		},
		nights_text: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		}
	}
}
</script>