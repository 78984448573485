<template>
	<div id="sidebar" class="sidebar" v-bind:class="{ mobile_hidden: hiddenSidebar }">
		<div class="sidebar__inner">
			<div class="hide_sidebar" @click="hideSidebar()"><i class="far fa-times-circle"></i></div>
			<div class="logo">
				<a :href="website" target="_blank"><img :src="logo" /></a>
			</div>
			<div class="company_name">{{ company_name }}</div>
			<div class="company_cin" v-if="cin">CIN: {{ cin }}</div>
			<div class="language_selector">
				<span v-if="this.langs.includes('IT')" v-bind:class="{selected: lang == 'IT'}"><img src="@/assets/images/flags/it.png" @click="setLang(0)" /></span>
				<span v-if="this.langs.includes('EN')" v-bind:class="{selected: lang == 'EN'}"><img src="@/assets/images/flags/en.png" @click="setLang(1)" /></span>
				<span v-if="this.langs.includes('DE')" v-bind:class="{selected: lang == 'DE'}"><img src="@/assets/images/flags/de.png" @click="setLang(2)" /></span>
				<span v-if="this.langs.includes('FR')" v-bind:class="{selected: lang == 'FR'}"><img src="@/assets/images/flags/fr.png" @click="setLang(3)" /></span>
				<span v-if="this.langs.includes('PT')" v-bind:class="{selected: lang == 'PT'}"><img src="@/assets/images/flags/pt.png" @click="setLang(4)" /></span>
				<span v-if="this.langs.includes('ES')" v-bind:class="{selected: lang == 'ES'}"><img src="@/assets/images/flags/es.png" @click="setLang(5)" /></span>
				<span v-if="this.langs.includes('RU')" v-bind:class="{selected: lang == 'RU'}"><img src="@/assets/images/flags/ru.png" @click="setLang(6)" /></span>
				<span v-if="this.langs.includes('CH')" v-bind:class="{selected: lang == 'CH'}"><img src="@/assets/images/flags/jp.png" @click="setLang(7)" /></span>
			</div>
			<div class="booking_type_selector" v-if="step < 2">
				<div v-bind:class="{ selected: booking_type == 1 }" @click="setBookingType(1)">
					{{ getTranslation('BOOK_ROOM') }}
				</div>
				<div v-bind:class="{ selected: booking_type == 2 }" @click="setBookingType(2)">
					{{ getTranslation('BOOK_PACKAGE') }}
				</div>
			</div>
			<div class="sidebar_container" v-if="booking_type == 1 && (step == 0 || step == 1) && currentRoom == 0">
				<SidebarSearchBanner></SidebarSearchBanner>
			</div>
			<div class="sidebar_container" v-if="booking_type == 2 && (step == 0 || step == 1 || step == 1.1 || step == 1.2)">
				<SidebarPackagesBanner></SidebarPackagesBanner>
			</div>
			<div class="sidebar_container" v-if="booking_type == 1 && (step > 1 || currentRoom > 0)">
				<SidebarSummary></SidebarSummary>
			</div>
			<div class="sidebar_container" v-if="booking_type == 2 && (step == 2 || step == 3 || step == 4)">
				<SidebarPackageSummary></SidebarPackageSummary>
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery'
import SidebarSearchBanner from './SidebarSearchBanner.vue'
import SidebarPackagesBanner from './SidebarPackagesBanner.vue'
import SidebarSummary from './SidebarSummary.vue'
import SidebarPackageSummary from './SidebarPackageSummary.vue'

export default {
	name: 'Sidebar',
	components: {
		SidebarSearchBanner,
        SidebarPackagesBanner,
        SidebarSummary,
        SidebarPackageSummary
	},
	data: function () {
		return {
			langs: []
		}
	},
	computed: {
		logo: function() {
			return encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/company/' + this.$store.state.company_info.logo);
		},
		company_name: function() {
			return this.$store.state.company_info.name;
		},
		website: function() {
			return 'http://' + this.$store.state.company_info.website;
		},
		cin: function() {
			return this.$store.state.company_info.cin;
		},
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		step: function() {
			return this.$store.state.step;
		},
		currentRoom: function() {
			return this.$store.state.current_room;
		},
		lang: function() {
			return this.$store.getters.get_lang_code;
		},
		showSidebar: function() {
			return this.$store.state.show_sidebar;
		},
		hiddenSidebar: function() {
			return !this.$store.state.show_sidebar;
		}
	},
	watch: {
		showSidebar: function(showSidebar) {
			if(showSidebar) {
				if(jQuery(window).width() <= 1023) {
					jQuery('body').addClass('overflow_hidden');
				}
			} else {
				jQuery('body').removeClass('overflow_hidden');
			}
		}
	},
	beforeCreate: function () {
		var self = this;

		this.$axios({
			method: 'get',
			url: '/booking/'+this.$company+'/getLangs'
		}).then(function (response) {
			self.langs = response.data;
		});
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		setLang: function(lang) {
			this.$store.commit('setLang', lang);
		},
		setBookingType: function(booking_type) {
			this.$store.commit('setBookingType', booking_type);
			if(booking_type == 1) {
				this.$store.dispatch('getSearchResults');
			} else if(booking_type == 2) {
				this.$store.commit('resetPackage');
				this.$store.dispatch('getPackagesSearchResults');
			}
		},
		hideSidebar: function() {
			this.$store.commit('setShowSidebar', false);
		}
	}
}
</script>