<template>
	<div id="extra-charges">
		<Loader v-if="!extra_charges"></Loader>
		<div class="extra_charges" v-if="extra_charges">
			<h2>{{ getTranslation('SELECTSERVICES') }}</h2>
			<div class="extra_charges_room" v-if="booking_type == 1">
				<span>{{ getTranslation('ROOM') }} <b>{{ current_room }}</b></span>
			</div>
			<ExtraCharge v-for="extra_charge in extra_charges" v-bind:key="extra_charge.ID" v-bind:extra_charge_data="extra_charge"></ExtraCharge>
			<div class="extra_charges_button_container">
				<div class="extra_charges_button" @click="goToCheckout()">{{ getTranslation('PROCEED') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from './Loader.vue'
import ExtraCharge from './ExtraCharge.vue'

export default {
	name: 'ExtraCharges',
	components: {
		Loader,
		ExtraCharge
	},
	computed: {
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		current_room: function() {
			return this.$store.state.current_room + 1;
		},
		extra_charges: function() {
			if(this.$store.state.booking_type == 1) {
				return this.$store.state.rooms[this.$store.state.current_room].extra_charges;
			} else if(this.$store.state.booking_type == 2) {
				return this.$store.state.package.extra_charges;
			}
			return false;
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		goToCheckout: function() {
			if(this.$store.state.current_room == this.$store.state.rooms.length - 1) {
				this.$store.commit('setStep', 3);
			} else {
				this.$store.commit('setCurrentRoom', this.$store.state.current_room + 1);
				this.$store.commit('setStep', 1);
				this.$store.dispatch('getSearchResults');
			}
		}
	}
}
</script>