<template>
	<div id="search-results">
		<loader v-if="!package_rooms"></loader>
		<div class="search_results_body" v-if="package_rooms.length > 0">
			<PackagesSearchRoom v-for="package_room in package_rooms" v-bind:key="package_room.ID" v-bind:result_data="package_room"></PackagesSearchRoom>
		</div>
	</div>
</template>

<script>
import PackagesSearchRoom from './PackagesSearchRoom.vue'

export default {
	name: 'PackagesSearchRooms',
	components: {
        PackagesSearchRoom
    },
    computed: {
		package_rooms: function() {
			return this.$store.state.package.package_rooms;
		}
	},
}
</script>