<template>
	<div id="main-window" class="main-window" v-bind:class="{ has_button_top: step < 3 }">
		<div v-if="loading">
			<Loader></Loader>
		</div>
		<div v-if="!loading && step == 1 && booking_type == 1 && !loading && !search_changed">
			<SearchResultsTop></SearchResultsTop>
			<SearchResults></SearchResults>
		</div>
		<div v-if="!loading && step == 1 && booking_type == 1 && !loading && search_changed">
			<SearchChanged></SearchChanged>
		</div>
		<div v-if="!loading && step == 1 && booking_type == 2 && !loading && !search_changed">
			<PackagesSearchResultsTop></PackagesSearchResultsTop>
			<PackagesSearchResults></PackagesSearchResults>
		</div>
		<div v-if="!loading && step == 1 && booking_type == 2 && !loading && search_changed">
			<SearchChanged></SearchChanged>
		</div>
		<div v-if="!loading && step == 1.1 && booking_type == 2 && !loading">
			<PackagesSearchCalendarTop></PackagesSearchCalendarTop>
			<PackagesSearchCalendar></PackagesSearchCalendar>
		</div>
		<div v-if="!loading && step == 1.2 && booking_type == 2 && !loading">
			<PackagesSearchRoomsTop></PackagesSearchRoomsTop>
			<PackagesSearchRooms></PackagesSearchRooms>
		</div>
		<div v-if="!loading && step == 2 && !loading">
			<ExtraCharges></ExtraCharges>
		</div>
		<div v-if="!loading && step == 3 && !loading">
			<Checkout></Checkout>
		</div>
		<div v-if="!loading && step == 4 && !loading">
			<ThankYou></ThankYou>
		</div>
	</div>
</template>

<script>
import Loader from './Loader.vue'
import SearchChanged from './SearchChanged.vue'
import SearchResultsTop from './SearchResultsTop.vue'
import SearchResults from './SearchResults.vue'
import PackagesSearchResultsTop from './PackagesSearchResultsTop.vue'
import PackagesSearchResults from './PackagesSearchResults.vue'
import PackagesSearchCalendarTop from './PackagesSearchCalendarTop.vue'
import PackagesSearchCalendar from './PackagesSearchCalendar.vue'
import PackagesSearchRoomsTop from './PackagesSearchRoomsTop.vue'
import PackagesSearchRooms from './PackagesSearchRooms.vue'
import ExtraCharges from './ExtraCharges.vue'
import Checkout from './Checkout.vue'
import ThankYou from './ThankYou.vue'

export default {
	name: 'MainWindow',
	components: {
		Loader,
		SearchChanged,
		SearchResultsTop,
		SearchResults,
		PackagesSearchResultsTop,
		PackagesSearchResults,
		PackagesSearchCalendarTop,
		PackagesSearchCalendar,
		PackagesSearchRoomsTop,
		PackagesSearchRooms,
		ExtraCharges,
		Checkout,
		ThankYou
	},
	computed: {
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		step: function() {
			return this.$store.state.step;
		},
		loading: function() {
			return this.$store.state.loading;
		},
		search_changed: function() {
			return this.$store.state.search_changed;
		}
	}
}
</script>