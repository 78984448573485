<template>
	<div id="extra-charge" class="extra_charge">
		<div class="extra_charge_row">
			<div class="extra_charge_left">
				<div>
					<img v-if="has_image" v-bind:src="image" />
				</div>
				<div>
					<h3 v-html="title"></h3>
					<span v-html="description"></span>
				</div>
			</div>
			<div class="extra_charge_right">
				<div class="price">{{ price }} €</div>
				<div class="choice">
					<div class="number">
						<div class="minus" v-bind:class="{ disabled: minusDisabled }" @click="decrease">-</div>
						<span id="adult">{{ quantity }}</span>
						<div class="plus" v-bind:class="{ disabled: plusDisabled }" @click="increase">+</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ExtraCharge',
	props: ['extra_charge_data'],
	computed: {
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		quantity: function() {
			return this.extra_charge_data.QUANTITY_SELECTED
		},
		minusDisabled: function() {
			if(this.quantity == 0) {
				return true;
			} else {
				return false;
			}
		},
		plusDisabled: function() {
			if(this.quantity == 1 && this.extra_charge_data.ISUNIT == 1) {
				return true;
			} else {
				return false;
			}
		},
		has_image: function() {
			if(this.extra_charge_data.IMAGE != '') {
				return true;
			} else {
				return false;
			}
		},
		image: function() {
			var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.extra_charge_data.IDCOMPANY+'/services/' + this.extra_charge_data.IDSERVICE + '/' + this.extra_charge_data.IMAGE);
			image = image.replace("(", "%28");
			image = image.replace(")", "%29");
			return image;
		},
		title: function() {
			return this.extra_charge_data['NAME_'+this.$store.getters.get_lang_code];
		},
		description: function() {
			return this.extra_charge_data['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		price: function() {
			return this.extra_charge_data.IMPORT_FORMATTED;
		},
		isUnit: function() {
			return this.extra_charge_data.ISUNIT;
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		increase: function() {
			let id = this.extra_charge_data.IDSERVICE;
			let quantity = this.quantity + 1;

			if(this.booking_type == 1) {
				this.$store.commit('updateExtraChargeQuantity', {id, quantity});
			} else if(this.booking_type == 2) {
				this.$store.commit('updatePackageExtraChargeQuantity', {id, quantity});
			}

			if(this.$store.state.tracking_codes['TRACKING_SCRIPT_SERVICE_SELECTED'] != undefined) {
				let tracking_code = this.$store.state.tracking_codes['TRACKING_SCRIPT_SERVICE_SELECTED'];
				tracking_code = tracking_code.replace(/%sn/g, this.extra_charge_data['NAME_IT']);
				tracking_code = tracking_code.replace(/%p/g, this.extra_charge_data['IMPORT']);
				eval(tracking_code);
			}
		},
		decrease: function() {
			if(this.quantity > 0) {
				let id = this.extra_charge_data.IDSERVICE;
				let quantity = this.quantity - 1;
				this.$store.commit('updateExtraChargeQuantity', {id, quantity});
			}
		}
	}
}
</script>