<template>
	<div id="search-result">
		<div class="search_result_row_1">
			<a class="search_result_image" v-bind:style="{ backgroundImage: 'url(' + image + ')' }" :href="image" :data-lightbox="'lightbox_'+this.result_data.ID">
				<span>{{ title }}</span>	
			</a>
			<div class="search_result_description">
				<div class="search_result_description_2_col">
					<div>
						<h4>{{ nights }} {{ nights_text }}</h4>
						<h3 v-html="title"></h3>
					</div>
					<div>
						<div class="search_result_button" @click="selectPackage()">{{ getTranslation('PROCEED') }}</div>
					</div>
				</div>
				<span v-html="description"></span>
			</div>
		</div>
		<div class="search_result_row_3">
			<div class="search_result_row_3_col">
				<h4>{{ getTranslation('BOOKABLEFROM') }}</h4>
				<span>{{ start_date }}</span>
			</div>
			<div class="search_result_row_3_col">
				<h4>{{ getTranslation('BOOKABLEDAYS') }}</h4>
				<span>
					<i class="fas fa-check" style="color: green;" v-if="validity_days[0] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[0] == 0"></i> {{ getTranslation('week_1_s') }} &nbsp;
					<i class="fas fa-check" style="color: green;" v-if="validity_days[1] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[1] == 0"></i> {{ getTranslation('week_2_s') }} &nbsp;
					<i class="fas fa-check" style="color: green;" v-if="validity_days[2] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[2] == 0"></i> {{ getTranslation('week_3_s') }} &nbsp;
					<i class="fas fa-check" style="color: green;" v-if="validity_days[3] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[3] == 0"></i> {{ getTranslation('week_4_s') }} <br/>
					<i class="fas fa-check" style="color: green;" v-if="validity_days[4] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[4] == 0"></i> {{ getTranslation('week_5_s') }} &nbsp;
					<i class="fas fa-check" style="color: green;" v-if="validity_days[5] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[5] == 0"></i> {{ getTranslation('week_6_s') }} &nbsp;
					<i class="fas fa-check" style="color: green;" v-if="validity_days[6] > 0"></i><i class="fas fa-times" style="color: red;" v-if="validity_days[6] == 0"></i> {{ getTranslation('week_0_s') }}
				</span>
			</div>
		</div>
		<div class="search_result_row_3" v-if="0">
			<div class="search_result_row_3_col">
				<h4>{{ getTranslation('TERMSCONDITIONS') }}</h4>
				<div>
					<div class="search_result_policy_arrangement" v-html="policy_descriptionservices"></div>
					<div class="search_result_policy_policy" v-html="policy_name"></div>
					<div class="search_result_policy_description" v-html="policy_description"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import lightbox from 'lightbox2'

export default {
	name: 'PackagesSearchResult',
	props: ['result_data'],
	computed: {
		image: function() {
			var image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+ this.result_data.IDCOMPANY +'/packages/' + this.result_data.ID + '/' + this.result_data.IMAGE);
			image = image.replace("(", "%28");
			image = image.replace(")", "%29");
			return image;
		},
		title: function() {
			return this.result_data['NAME_'+this.$store.getters.get_lang_code];
		},
		description: function() {
			return this.result_data['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		policy_name: function() {
			return this.result_data['POLICY_NAME_'+this.$store.getters.get_lang_code];
		},
		policy_description: function() {
			return this.result_data['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		policy_descriptionservices: function() {
			return this.result_data['POLICY_DESCRIPTIONSERVICES_'+this.$store.getters.get_lang_code];
		},
		nights: function() {
			return this.result_data['NRDAYS'];
		},
		nights_text: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		},
		start_date: function() {
			let start_date = new Date(this.result_data['INIZIO']);
			let now = new Date();
			if(start_date.getTime() < now.getTime()) {
				return this.$formatDateIt(now);
			} else {
				return this.$formatDateIt(start_date);
			}			
		},
		validity_days: function() {
			return this.result_data['VALIDITYDAYS'];
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		selectPackage: function() {
			this.$store.commit('setPackageSelected', this.result_data);
			this.$store.dispatch('getPackagesSearchCalendar');
		}
	},
	mounted: function() {
		lightbox.option({
			albumLabel: '',
			wrapAround: true,
			fadeDuration: 300
		})

		let urlParams = new URLSearchParams(window.location.search);

		let package_id = urlParams.get('package');
        if(package_id == this.result_data.ID) {
			this.selectPackage();
        }
	}
}
</script>