export default {
    install (Vue) {

        Vue.prototype.$calculateNights = function(check_in, check_out) {
            var difference_in_time = check_out.getTime() - check_in.getTime(); 
            var difference_in_days = difference_in_time / (1000 * 3600 * 24);
            return Math.round(difference_in_days);
        };

        Vue.prototype.$formatDate = function(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        };

        Vue.prototype.$formatDateIt = function(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [day, month, year].join('-');
        };

        Vue.prototype.$formatDateLocale = function(date, locale) {
            var d = new Date(date);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            return d.toLocaleDateString(locale, options);
        };

        Vue.prototype.$updateUrlParams = function(state) {
            if(state.initialized == true) {
                let urlParams = new URLSearchParams(window.location.search);

                let keys = [];

                for (const key of urlParams.keys()) {
                    keys.push(key);
                }

                keys.forEach(function(key) {
                    urlParams.delete(key);
                });

                urlParams.set('IDCOMPANY', state.company_id);

                switch(state.lang) {
                    case 0:
                        urlParams.set('lang', 'IT');
                        break;
                    case 1:
                        urlParams.set('lang', 'EN');
                        break;
                    case 2:
                        urlParams.set('lang', 'DE');
                        break;
                    case 3:
                        urlParams.set('lang', 'FR');
                        break;
                    case 4:
                        urlParams.set('lang', 'PT');
                        break;
                    case 5:
                        urlParams.set('lang', 'ES');
                        break;
                    case 6:
                        urlParams.set('lang', 'RU');
                        break;
                    case 7:
                        urlParams.set('lang', 'CH');
                        break;
                }

                urlParams.set('room_type', state.room_type);

                if(state.booking_type == 1) {
                    urlParams.set('IN', state.check_in);
                    urlParams.set('OUT', state.check_out);
                    urlParams.set('ROOMS_NUM', state.rooms.length);

                    for(let i = 0; i < state.rooms.length; i++) {
                        urlParams.set('R'+(i+1)+'_ADULT', state.rooms[i].adults);
                        urlParams.set('R'+(i+1)+'_CHILD', state.rooms[i].child1);
                        urlParams.set('R'+(i+1)+'_CHILD2', state.rooms[i].child2);
                        urlParams.set('R'+(i+1)+'_INFANT', state.rooms[i].infants);
                    }
                } else if(state.booking_type == 2) {
                    if(state.package.package_selected.ID) {
                        urlParams.set('package', state.package.package_selected.ID);
                    } else {
                        urlParams.set('package', -1);
                    }
                }

                window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
            }
        };
    }

}