<template>
	<div id="package-search-calendar">
		<div class="package_search_calendar">
			<h3>{{ getTranslation('PACKAGE_ARRIVAL_DATE') }}</h3>
			<div id="package-calendar"></div>
		</div>
		<div class="package_search_calendar_button_container">
			<div class="package_search_calendar_button" v-bind:class="{ disabled: day_selected == '' }" @click="getPackageDayRooms()">{{ getTranslation('PROCEED') }}</div>
		</div>
	</div>
</template>

<script>
import Litepicker from 'litepicker';

export default {
	name: 'PackagesSearchCalendar',
	data: function () {
		return {
			day_selected: ''
		}
	},
	computed: {
		check_in: function() {
			return this.$store.state.check_in;
		},
		package_calendar: function() {
			return this.$store.state.package.package_calendar;
		},
		lockdays: function() {
			let days = Object.keys(this.$store.state.package.package_calendar);
			let lockdays = [];
			for(let d = 0; d < days.length; d++) {
				let empty = true;
				for(let i = 0; i < this.$store.state.package.package_calendar[days[d]].length; i++) {
					if(this.$store.state.package.package_calendar[days[d]][i]['QTY'] > 0) {
						empty = false;
					}
				}
				if(empty) {
					lockdays.push(days[d]);
				}
			}
			return lockdays;
		},
		calendar_lang: function() {
			return this.$store.getters.get_date_lang_code;	
		}
	},
	watch: {
		calendar_lang: function(lang) {
			this.picker.setOptions({
				lang: lang
			});
		},
		lockdays: function(lockdays) {
			this.picker.setLockDays(lockdays);
		}
	},
	mounted: function () {
		var self = this;
		var today = new Date();
		today.setDate(today.getDate() - 1);

		this.picker = new Litepicker({
			element: document.getElementById('package-calendar'),
			lang: this.calendar_lang,
			inlineMode: true,
			singleMode: true,
			autoRefresh: true,
			startDate: this.check_in,
			minDate: today,
			lockDays: this.lockdays,
			setup: (picker) => {
				picker.on('change:month', (date) => {
					let check_in = new Date(date.getTime());
					let check_out = new Date(date.getTime());
					check_out.setDate(date.getDate() + self.$store.state.package.package_selected['NRDAYS']);
					self.$store.commit('setCheckIn', self.$formatDate(check_in));
					self.$store.commit('setCheckOut', self.$formatDate(check_out));
					self.$store.dispatch('getPackagesSearchCalendar');
				});
				picker.on('selected', (check_in) => {
					let check_out = new Date(check_in.dateInstance.getTime());
					check_out.setDate(check_in.dateInstance.getDate() + self.$store.state.package.package_selected['NRDAYS']);
					self.$store.commit('setCheckIn', self.$formatDate(check_in.dateInstance));
					self.day_selected = self.$formatDate(check_in.dateInstance);
					self.$store.commit('setCheckOut', self.$formatDate(check_out));
				});
			}
		});
		this.picker.clearSelection();
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		getPackageDayRooms: function() {
			this.$store.dispatch('getPackagesSearchRooms');
		}
	}
}
</script>