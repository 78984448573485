<template>
	<div class="search-results-more-nights-availability">
		<h3>{{ getTranslation('MINIMUM_STAY') }} {{ nights }} {{ getTranslation('NIGHTS').toLowerCase() }}</h3>
		<h4>{{ getTranslation('MINIMUM_STAY_2') }} {{ nights }} {{ getTranslation('NIGHTS').toLowerCase() }}</h4>
		<div class="more_nights_availability_button_container">
			<div class="more_nights_availability_button" @click="setCheckOut()">{{ getTranslation('EDIT_CHECKOUT_DATE') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchResultsMoreNightsAvailability',
	props: ['nights'],
	computed: {
		
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		setCheckOut: function() {
			var checkin = new Date(this.$store.state.last_search.check_in.replace(/-/g, '/'));
			var checkout = new Date(checkin.getTime() + (this.nights*60*60*24*1000));
			this.$store.commit('setCheckOut', this.$formatDate(checkout));
			this.$store.dispatch('getSearchResults');
		}
	}
}
</script>