<template>
	<div id="loader">
		<i class="fas fa-spinner fa-spin"></i>
	</div>
</template>

<script>
export default {
	name: 'Loader'
}
</script>