<template>
    <div class="delete_reservation" id="delete_reservation">
        <div v-if="loaded && penalty != -1 && penalty != -2 && penalty != -99 && !success">
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_1') }}:</div>
            <div class="delete_reservation_prenotation_number">BOL_{{ reservation_id }}</div>
            <br/><br/>
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_2') }}:</div>
            <div class="delete_reservation_date">{{ check_in }} - {{ check_out }}</div>
            <br/><br/>
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_3') }}:</div>
            <div class="delete_reservation_prenotation_number">€ {{ penalty }}</div>
            <br/><br/>
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_4') }}</div>
            <div class="delete_reservation_button" @click="cancelReservation">{{ getTranslation('RESERVATION_DELETE_5') }}</div>
        </div>
        <div v-if="loaded && penalty == -1 && !success">
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_6') }}</div>
        </div>
        <div v-if="loaded && success">
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_7') }}</div>
        </div>
        <div v-if="loaded && penalty == -2 && !success">
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_8') }}</div>
        </div>
        <div v-if="loaded && penalty == -99">
            <div class="delete_reservation_text">{{ getTranslation('RESERVATION_DELETE_9') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeleteReservation',
    data: function () {
        return {
            loaded: false,
            success: false,
            reservation_id: 0,
            validation_token: '',
            check_in: '',
            check_out: '',
            penalty: '0.00'
        }
    },
    methods: {
        getTranslation: function(key) {
            return this.$store.getters.get_translation(key);
        },
        cancelReservation() {
            var self = this;

            var bodyFormData = new FormData();
            bodyFormData.append('reservation_id', self.reservation_id);
            bodyFormData.append('validation_token', self.validation_token);

            self.$axios({
                method: 'post',
                url: '/booking/'+self.$company+'/cancelReservation',
                data: bodyFormData
            }).then(function (response) {
                if(response.data > 0) {
                    self.success = true;
                    self.$axios({
                        method: 'post',
                        url: '/booking/'+self.$company+'/sendCancelEmail/'+self.$store.state.company_id,
                        data: bodyFormData
                    });
                } else {
                    self.success = false;
                    self.penalty = -99;
                }
            });
        }
    },
    mounted: function () {
        var self = this;

        let params = window.location.pathname.split('/').slice(1)
        this.reservation_id = params[2]
        this.validation_token = params[3]

        this.$axios({
            method: 'get',
            url: '/booking/'+this.$company+'/getReservationToCancelInfos/'+this.reservation_id+'/'+this.validation_token
        }).then(function (response) {
            if(response.data < 0) {
                self.penalty = response.data;
            } else {
                self.check_in = response.data.check_in
                self.check_out = response.data.check_out
                self.penalty = response.data.penalty
            }
            self.loaded = true;
        });
    },
}
</script>