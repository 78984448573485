<template>
	<div id="show_sidebar" class="show_sidebar" @click="revealSidebar()" v-if='step < 3'>
		{{ showSidebarText }}
	</div>
</template>

<script>
export default {
	name: 'SidebarShowMobile',
	computed: {
		showSidebar: function() {
			return this.$store.state.show_sidebar;
		},
		showSidebarText: function() {
			if(this.$store.state.step <= 1) {
				return 'EFFETTUA NUOVA RICERCA';
			} else {
				return 'VISUALIZZA RIEPILOGO';
			}
		},
		step: function() {
			return this.$store.state.step;
		}
	},
	methods: {
		revealSidebar: function() {
			this.$store.commit('setShowSidebar', true);
		}
	}
}
</script>