<template>
	<div class="search-results-other-availability">
		<div class="other_availablity_row" v-bind:class="{ disabled: this.availability_row.QTY == 0, orange: this.availability_row.QTY == 1, yellow: this.availability_row.QTY > 1 && this.availability_row.QTY < 11, green: this.availability_row.QTY >= 11 }" @click="setCheckInCheckOut()">
			<div>
				<span>{{ getTranslation('CHECKIN') }}:&nbsp;</span>
				<span>{{ check_in_formatted }}</span>
			</div>
			<div v-if="text != ''">
				<span>{{ text }}</span>&nbsp;
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchResultsOtherAvailability',
	props: ['availability_row'],
	computed: {
		check_in_formatted: function() {
			return this.$formatDateLocale(this.availability_row.DATE.replace(/-/g, '/'));
		},
		text: function() {
			if(this.availability_row.QTY == 1) {
				return this.getTranslation('LASTAVAILABLERESOURCE');
			} else if(this.availability_row.QTY > 0 && this.availability_row.QTY < 11) {
				return this.getTranslation('FEWAVAILABLERESOURCES');
			} else {
				return '';
			}
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		setCheckInCheckOut: function() {
			this.$store.commit('setCheckIn', this.availability_row.DATE);
			var tomorrow = new Date(this.availability_row.DATE.replace(/-/g, '/'));
			tomorrow.setDate(tomorrow.getDate() + 1);
			this.$store.commit('setCheckOut', this.$formatDate(tomorrow));
			this.$store.dispatch('getSearchResults');
		}
	}
}
</script>