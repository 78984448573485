<template>
	<div id="search-results" class="package_search_results">
		<loader v-if="check_in && !search_results"></loader>
		<div class="search_results_body" v-if="search_results.length > 0">
			<PackagesSearchResult v-for="search_result in search_results" v-bind:key="search_result.ID" v-bind:result_data="search_result"></PackagesSearchResult>
		</div>
		<div class="search_results_other_availabilities" v-if="search_results.length == 0">
			<div class="search_results_other_availabilities_box">
				<h3>Nessuna disponibilità per la ricerca effettuata.</h3>
			</div>
		</div>
	</div>
</template>

<script>
import PackagesSearchResult from './PackagesSearchResult.vue'
	
export default {
	name: 'PackagesSearchResults',
	components: {
		PackagesSearchResult
	},
	computed: {
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		search_results: function() {
			return this.$store.state.results;
		},
		other_availabilities: function() {
			return this.$store.state.other_availabilities;
		}
	}
}
</script>