<template>
	<div class="search_banner">
		<div class="search_panel" v-if="room_types.length > 0">
			<label>{{ getTranslation('INTERESTEDIN') }}</label>
			<select id="room_type" name="room_type" v-model="room_type">
				<option value="0">{{ getTranslation('ALL') }}</option>
				<option v-for="room_type in room_types" v-bind:key="room_type.ID" v-bind:value="room_type.ID">{{ room_type.NAME_IT }}</option>
			</select>
		</div>
		<div class="search_panel" v-bind:style="[room_types.length > 0 ? {'margin-top': '25px'} : {'margin-top': '0px'}]">
			<span class="guests">
				<div class="number">
					<div class="minus" :class="{disabled: adults <= 0 || adults <= min_adult}" @click="decreaseAdults">-</div>
					<span id="adult">{{ adults }}</span>
					<div class="plus" :class="{disabled: adults >= max_adult}" @click="increaseAdults">+</div>
				</div>
				<label>{{ getTranslation('ADULT') }}</label>
			</span>
			<span class="guests" v-if="ages.child1_age != null">
				<div class="number">
					<div class="minus" :class="{disabled: child1 <= 0}" @click="decreaseChild1">-</div>
					<span id="adult">{{ child1 }}</span>
					<div class="plus" :class="{disabled: child1 >= max_child1}" @click="increaseChild1">+</div>
				</div>
				<label>{{ getTranslation('CHILD') }} ({{ ages.child1_age }})</label>
			</span>
			<span class="guests" v-if="ages.child2_age != null">
				<div class="number">
					<div class="minus" :class="{disabled: child2 <= 0}" @click="decreaseChild2">-</div>
					<span id="adult">{{ child2 }}</span>
					<div class="plus" :class="{disabled: child2 >= max_child2}" @click="increaseChild2">+</div>
				</div>
				<label>{{ getTranslation('CHILD') }} ({{ ages.child2_age }})</label>
			</span>
			<span class="guests" v-if="ages.infant_age != null">
				<div class="number">
					<div class="minus" :class="{disabled: infants <= 0}" @click="decreaseInfants">-</div>
					<span id="adult">{{ infants }}</span>
					<div class="plus" :class="{disabled: infants >= max_infant}" @click="increaseInfants">+</div>
				</div>
				<label>{{ getTranslation('INFANT') }} ({{ ages.infant_age }})</label>
			</span>
		</div>
		<div class="search_panel" style="margin-top: 25px;">
			<div class="search_button" @click="searchResults">{{ getTranslation('CHECKAVAILABILITY') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SidebarPackagesBanner',
	computed: {
		ages: function() {
			return this.$store.state.ages;
		},
		room_types: function() {
			return this.$store.state.room_types;
		},
		adults: function() {
			return this.$store.state.package.adults;
		},
		child1: function() {
			return this.$store.state.package.child1;
		},
		child2: function() {
			return this.$store.state.package.child2;
		},
		infants: function() {
			return this.$store.state.package.infants;
		},
		min_adult: function() {
			return this.$store.state.company_info.min_adult;
		},
		max_adult: function() {
			return this.$store.state.company_info.max_adult;
		},
		max_child1: function() {
			return this.$store.state.company_info.max_child1;
		},
		max_child2: function() {
			return this.$store.state.company_info.max_child2;
		},
		max_infant: function() {
			return this.$store.state.company_info.max_infant;
		},
		room_type: {
			get() {
                return this.$store.state.room_type;
            },
            set(room_type) {
                this.$store.commit('setRoomType', room_type);
            }
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		increaseAdults: function() {
			this.$store.commit('setPackageAdults', this.adults + 1);
		},
		decreaseAdults: function() {
			if(this.adults > 0) {
				this.$store.commit('setPackageAdults', this.adults - 1);
			}			
		},
		increaseChild1: function() {
			this.$store.commit('setPackageChild1', this.child1 + 1);
		},
		decreaseChild1: function() {
			if(this.child1 > 0) {
				this.$store.commit('setPackageChild1', this.child1 - 1);
			}
		},
		increaseChild2: function() {
			this.$store.commit('setPackageChild2', this.child2 + 1);
		},
		decreaseChild2: function() {
			if(this.child2 > 0) {
				this.$store.commit('setPackageChild2', this.child2 - 1);
			}
		},
		increaseInfants: function() {
			this.$store.commit('setPackageInfants', this.infants + 1);
		},
		decreaseInfants: function() {
			if(this.infants > 0) {
				this.$store.commit('setPackageInfants', this.infants - 1);
			}
		},
		searchResults: function() {
			this.$store.commit('resetPackage');
			this.$store.dispatch('getPackagesSearchResults');
			this.$store.commit('setShowSidebar', false);
		}
	}
}
</script>