<template>
  <div class="booking_main" id="booking_main">
    <SidebarShowMobile></SidebarShowMobile>
    <Sidebar></Sidebar>
    <MainWindow></MainWindow>
  </div>
</template>

<script>
import SidebarShowMobile from './SidebarShowMobile.vue'
import Sidebar from './Sidebar.vue'
import MainWindow from './MainWindow.vue'

export default {
    name: 'BookingMain',
    components: {
        SidebarShowMobile,
        Sidebar,
        MainWindow
    }
}
</script>