<template>
	<div id="package-search-results-top">
		<div class="search_summary_row">
			<div>
				{{ getTranslation('RESULTSFOR') }}: &nbsp; 
				<span v-if="adults > 0"><i class="fas fa-male" v-for="index in adults" v-bind:key="index"></i> <span>{{ adults }} {{ adults_text }}</span>&nbsp;</span>
				<span v-if="child1 > 0"><i class="fas fa-child" v-for="index in child1" v-bind:key="index"></i> <span>{{ child1 }} {{ child1_text }} <span>({{ ages.child1_age }})</span></span>&nbsp;</span>
				<span v-if="child2 > 0"><i class="fas fa-child" v-for="index in child2" v-bind:key="index"></i> <span>{{ child2 }} {{ child2_text }} <span>({{ ages.child2_age }})</span></span>&nbsp;</span>
				<span v-if="infants > 0"><i class="fas fa-baby" v-for="index in infants" v-bind:key="index"></i> <span>{{ infants }} {{ infants_text }} <span>({{ ages.infant_age }})</span></span>&nbsp;</span>
			</div>
			<div>
				{{ getTranslation('PACKAGE') }}: &nbsp; 
				<span>{{ b_package }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PackagesSearchCalendarTop',
	computed: {
		ages: function() {
			return this.$store.state.ages;
		},
		adults: function() {
			return this.$store.state.last_search.adults;
		},
		adults_text: function() {
			if(this.adults == 1) {
				return this.getTranslation('ADULT_SINGLE');
			} else {
				return this.getTranslation('ADULT');
			}
		},
		child1: function() {
			return this.$store.state.last_search.child1;
		},
		child1_text: function() {
			if(this.child1 == 1) {
				return this.getTranslation('CHILD_SINGLE');
			} else {
				return this.getTranslation('CHILD');
			}
		},
		child2: function() {
			return this.$store.state.last_search.child2;
		},
		child2_text: function() {
			if(this.child2 == 1) {
				return this.getTranslation('CHILD_SINGLE');
			} else {
				return this.getTranslation('CHILD');
			}
		},
		infants: function() {
			return this.$store.state.last_search.infants;
		},
		infants_text: function() {
			if(this.infants == 1) {
				return this.getTranslation('INFANT_SINGLE');
			} else {
				return this.getTranslation('INFANT');
			}
		},
		b_package: function() {
			return this.$store.state.package.package_selected['NAME_'+this.$store.getters.get_lang_code];
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		}
	}
}
</script>