<template>
	<div class="sidebar_summary">
		<div class="summary_block_1">
			<div class="summary_block_1_row">
				<div class="summary_block_1_col">
					<span>{{ getTranslation('CHECKIN') }}</span>
					{{ check_in_formatted }}
				</div>
				<div class="summary_block_1_col">
					<span>{{ getTranslation('CHECKOUT') }}</span>
					{{ check_out_formatted }}
				</div>
			</div>
			<div class="summary_block_1_row">
				<div class="summary_block_1_col label_2_rows">
					<span>{{ getTranslation('ADULT') }}</span>
					{{ adults }}
				</div>
				<div class="summary_block_1_col label_2_rows" v-if="ages.child1_age != null">
					<span>{{ getTranslation('CHILD') }}<br/><span>{{ ages.child1_age }}</span></span>
					{{ child1 }}
				</div>
				<div class="summary_block_1_col label_2_rows" v-if="ages.child2_age != null">
					<span>{{ getTranslation('CHILD') }}<br/><span>{{ ages.child2_age }}</span></span>
					{{ child2 }}
				</div>
				<div class="summary_block_1_col label_2_rows" v-if="ages.infant_age != null">
					<span>{{ getTranslation('INFANT') }}<br/><span>{{ ages.infant_age }}</span></span>
					{{ infants }}
				</div>
			</div>
		</div>
		<div class="summary_block_room">
			<div class="summary_block_2" style="margin-top: 0px;">
				<div class="summary_block_2_name" v-html="package_name"></div>
				<div class="summary_block_2_description" v-html="package_description"></div>
			</div>
			<div class="summary_block_2">
				<div class="summary_block_2_name" v-html="room_name"></div>
				<div class="summary_block_2_description" v-html="room_description"></div>
				<div class="summary_block_2_price" style="margin-top: 15px;">{{ room_price }} €</div>
			</div>
			<div class="summary_block_3" style="margin-top: 20px;" v-if="selected_extra_charges.length > 0">
				<div class="summary_block_3_row" style="margin-top: 15px;" v-for="extra_charge in selected_extra_charges" v-bind:key="extra_charge.IDSERVICE">
					<div class="summary_block_3_name" v-html="getExtraChargeName(extra_charge)"></div>
					<div class="summary_block_3_description" v-html="getExtraChargeDescription(extra_charge)"></div>
					<div class="summary_block_3_price" style="margin-top: 15px;">
						{{ extra_charge.QUANTITY_SELECTED }} x <b>{{ extra_charge.IMPORT_FORMATTED }} €</b>
					</div>
				</div>
			</div>
		</div>
		<div class="summary_block_total">
			<div>{{ getTranslation('TOTALPRICE') }}:</div>
			<div>{{ total }} €</div>
		</div>
		<hr/>
		<div class="summary_block_reset" @click="backToSearch()">
			{{ getTranslation('NEWSEARCH') }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'SidebarPackageSummary',
	computed: {
		ages: function() {
			return this.$store.state.ages;
		},
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		check_in_formatted: function() {
			return this.$formatDateIt(this.check_in.replace(/-/g, '/'));
		},
		check_out: function() {
			return this.$store.state.last_search.check_out;
		},
		check_out_formatted: function() {
			return this.$formatDateIt(this.check_out.replace(/-/g, '/'));
		},
		adults: function() {
			return this.$store.state.last_search.adults;
		},
		child1: function() {
			return this.$store.state.last_search.child1;
		},
		child2: function() {
			return this.$store.state.last_search.child2;
		},
		infants: function() {
			return this.$store.state.last_search.infants;
		},
		package_name: function() {
			return this.$store.state.package.package_selected['NAME_'+this.$store.getters.get_lang_code];
		},
		package_description: function() {
			return this.$store.state.package.package_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		room_name: function() {
			return this.$store.state.package.package_room_selected['NAME_'+this.$store.getters.get_lang_code];
		},
		room_description: function() {
			return this.$store.state.package.package_room_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		room_price: function() {
			return this.$store.state.package.package_room_selected.TOTAL_PRICE_FORMATTED;
		},
		selected_extra_charges: function() {
			return this.$store.getters.selected_package_extra_charges;
		},
		total: function() {
			let total = this.$store.state.package.package_room_selected.TOTAL_PRICE;
			for(let i = 0; i < this.$store.getters.selected_package_extra_charges.length; i++) {
				total += this.$store.getters.selected_package_extra_charges[i].IMPORT * this.$store.getters.selected_package_extra_charges[i].QUANTITY_SELECTED;
			}
			return total.toFixed(2).replace(".", ",");
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		getExtraChargeName: function(extra_charge) {
			return extra_charge['NAME_'+this.$store.getters.get_lang_code];
		},
		getExtraChargeDescription: function(extra_charge) {
			return extra_charge['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		backToSearch: function() {
			this.$store.dispatch('backToSearch');
		}
	}
}
</script>